import { PageProps } from '@/types'
import { usePage } from '@inertiajs/react'
import type { FC } from 'react'

const ZAR = (v: number, maximumFractionDigits: number) =>
  Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    currencyDisplay: 'symbol',
    maximumFractionDigits: maximumFractionDigits
  }).format(v)

export const IntlMoney: FC<{
  value: number
  maximumFractionDigits?: number
}> = ({ value, maximumFractionDigits = 2 }) => {
  const { hide_amounts } = usePage<PageProps>().props

  return <>{hide_amounts ? 'R *****' : ZAR(value, maximumFractionDigits)}</>
}
